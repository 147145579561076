



























import WelcomeForm from '../components/WelcomeForm.vue';
import { defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { WelcomeForm },
  name: 'WelcomePage',
  props: {
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      appLogo: require('@/assets/logo.png'),
      routeNames,
    };
  },
});
